import Axios from "axios";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import GraphData from "./Graph";
import { JsonToExcel } from "react-json-to-excel";

function App() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
    city: "",
    state: "New Mexico",
    zip: "",
  });

  const [errorMessages, setErrorMessages] = useState("Loading");

  const [outputUrl, setOutputUrl] = useState("");

  const [CampaignName, setCampaignName] = useState("");
  const [CampaignSource, setCampaignSource] = useState("");
  const [AndroidUrl, setAndroidUrl] = useState("");
  const [IosUrl, setIosUrl] = useState("");

  const [utmCampaignName, setUtmCampaignName] = useState("");
  const [utmCampaignSource, setUtmCampaignSource] = useState("");
  const [utmCampaignMedium, setUtmCampaignMedium] = useState("");
  const [utmCampaignTerm, setUtmCampaignTerm] = useState("");
  const [utmCampaignContent, setUtmCampaignContent] = useState("");
  const [playUrl, setPlayUrl] = useState("");
  const [allUtmData, setAllUtmData] = useState([]);
  const [showGraph, setShowGraph] = useState(false);

  const [currentUuid, setCurrentUuid] = useState("yfxndzubrjacy9nxsv37kp");
  const [graphData, setGraphData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addUtmData = async () => {
    const data = {
      campaign_name: CampaignName,
      campaign_source: CampaignSource,
      android_url: AndroidUrl,
      ios_url: IosUrl,
    };
    const res = await Axios.post(
      "https://dlutm.lvl.fit/utm/add/utm",
      data
    );
    if (res?.data?.id == 1) {
      setOutputUrl(res?.data?.unique_url);
      toast.success("UTM Created Successfully", {
        icon: "🤌",
      });
    } else {
      toast.error(res?.data?.message, {
        icon: "🐒",
      });
    }
  };

  //Get All UTM Data
  const getUtmData = async () => {
    const res = await Axios.get(
      "https://dlutm.lvl.fit/utm/get/all/utm"
    );

    res.data[0] = {
      campaign_name: "Complete Website",
      utm_builder_data: {
        click: 0,
        android_clicks: 0,
        ios_clicks: 0,
        unknown_clicks: 0,
      },
      total_installs: 0,
      total_onboarding_completed: 0,
      total_onboarding_not_completed: 0,
      first_activity_completed: 0,
      first_activity_not_completed: 0,
      subscription_data: {
        total_subscriptions: 0,
        total_cancelled: 0,
        monthly_subscriptions: 0,
        sixMonth_subscriptions: 0,
        annual_subscriptions: 0,
        people_on_1_month: 0,
        people_on_2_month: 0,
        people_on_3_month: 0,
      },

    };

    for (var i = 0; i < res.data.length; i++) {
      if (res.data[i]?.campaign_name.includes("blog") || res.data[i]?.campaign_name.includes("SEO") || res.data[i]?.campaign_name.includes("website")) {
        //Put in one object
        res.data[0].utm_builder_data.click += res.data[i].utm_builder_data.click;
        res.data[0].utm_builder_data.android_clicks += res.data[i].utm_builder_data.android_clicks;
        res.data[0].utm_builder_data.ios_clicks += res.data[i].utm_builder_data.ios_clicks;
        res.data[0].utm_builder_data.unknown_clicks += res.data[i].utm_builder_data.unknown_clicks;
        res.data[0].total_installs += res.data[i].total_installs;
        res.data[0].total_onboarding_completed += res.data[i].total_onboarding_completed;
        res.data[0].total_onboarding_not_completed += res.data[i].total_onboarding_not_completed;
        res.data[0].first_activity_completed += res.data[i].first_activity_completed;
        res.data[0].first_activity_not_completed += res.data[i].first_activity_not_completed;
        res.data[0].subscription_data.total_subscriptions += res.data[i].subscription_data.total_subscriptions;
        res.data[0].subscription_data.total_cancelled += res.data[i].subscription_data.total_cancelled;
        res.data[0].subscription_data.monthly_subscriptions += res.data[i].subscription_data.monthly_subscriptions;
        res.data[0].subscription_data.sixMonth_subscriptions += res.data[i].subscription_data.sixMonth_subscriptions;
        res.data[0].subscription_data.annual_subscriptions += res.data[i].subscription_data.annual_subscriptions;
        res.data[0].subscription_data.people_on_1_month += res.data[i].subscription_data.people_on_1_month;
        res.data[0].subscription_data.people_on_2_month += res.data[i].subscription_data.people_on_2_month;
        res.data[0].subscription_data.people_on_3_month += res.data[i].subscription_data.people_on_3_month;
      }
    }

    setAllUtmData(res.data);
  };

  const getGraphData = async (uuid) => {
    console.log(uuid);
    const res = await Axios.get(
      "https://dlutm.lvl.fit/utm/analyse/data/" + uuid
    );
    setGraphData(res.data);
  };

  const syncData = async () => {
    const res = await Axios.get(
      "https://dlutm.lvl.fit/utm/sync/utm"
    );
    //refresh page
    window.location.reload();
  };

  async function getErrors() {
    setErrorMessages("0");
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      // Your function logic here
      getErrors();
    }, 5000);

    return () => clearTimeout(timer); // This will clear the timeout if the component is unmounted
  }, []); // Empty dependency array means this effect runs once on mount



  useEffect(() => {
    getUtmData();
  }, []);

  return (
    <>
      {!showGraph ? (
        <div>

          <nav className="bg-black p-2">
            <Toaster />

            <div className="container mx-auto flex justify-between items-center">
              <div className="flex items-center">
                {/* Larger Logo */}
                <h1 className="text-white text-2xl font-semibold">
                  Utm Builder
                </h1>
              </div>

              <div className="space-x-4">
                {/* Navigation Links */}
                <a href="#" className="text-white">
                  Deep Link Builder
                </a>
                <a href="#" className="text-white">
                  Analytics
                </a>
                <a href="#" className="text-white">
                  Logout
                </a>
              </div>
            </div>
          </nav>

          <div className="text-center">
            <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-white dark:border-gray-700">
              <a href="#">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-black">
                  Error Rate
                </h5>
              </a>
              <p className="mb-3 font-normal text-gray-700 dark:text-black">
                {errorMessages} Fails
              </p>
            </div>
          </div>
          <JsonToExcel
            title="Download as Excel"
            data={allUtmData}
            fileName="all-data"
            btnClassName="custom-classname"
          />

          <div className="flex flex-wrap justify-between bg-white px-4 py-2">
            {/* First Form on Top Left */}
            <div className="w-full md:w-1/2 p-4">
              <div className="bg-white rounded shadow-lg p-8 max-w-lg mx-auto">
                <div className="text-center">
                  <h2 className="text-2xl font-semibold mb-4">
                    Add Campaign Data
                  </h2>
                </div>
                <div className="text-center">
                  {/* Display copied message */}
                  {outputUrl !== "" ? (
                    <div
                      className="p-2 mt-4 mb-4 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="pl-2 font-semibold mr-2 text-left flex-auto">
                        Your URL has been generated
                      </span>

                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            "https://install.lvl.fit/" + outputUrl
                          );
                          toast("Copied", {
                            icon: "🤌",
                          });
                        }}
                      >
                        <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                          Copy
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <form className="w-full max-w-lg mx-auto">
                  {/* Your form fields */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="Campaign Name"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Campaign Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={CampaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                        placeholder="Ranveer"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                      <label
                        htmlFor="lastName"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Campaign Source
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={CampaignSource}
                        onChange={(e) => setCampaignSource(e.target.value)}
                        placeholder="Youtube"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="city"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Android URL
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={AndroidUrl}
                        onChange={(e) => setAndroidUrl(e.target.value)}
                        placeholder="play.google.com"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="zip"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        iOS URL
                      </label>
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        value={IosUrl}
                        onChange={(e) => setIosUrl(e.target.value)}
                        placeholder="app.apple.com"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </form>
                {/* Add Button */}
                <div className="text-center mt-4">
                  <button
                    onClick={() => {
                      addUtmData();
                    }}
                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                  >
                    Add Tracking
                  </button>
                </div>
              </div>
            </div>

            {/* Second Form on Top Right */}
            <div className="w-full md:w-1/2 p-4">
              <div className="bg-white rounded shadow-lg p-8 max-w-lg mx-auto">
                <div className="text-center">
                  <h2 className="text-2xl font-semibold mb-4">
                    PlayStore UTM Link Generator
                  </h2>
                </div>

                <div className="text-center">
                  {/* Display copied message */}
                  {playUrl !== "" ? (
                    <div
                      className="p-2 mt-4 mb-4 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
                      role="alert"
                    >
                      <span className="pl-2 font-semibold mr-2 text-left flex-auto">
                        Your URL has been generated
                      </span>

                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(playUrl);
                          toast("Copied", {
                            icon: "🤌",
                          });
                        }}
                      >
                        <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                          Copy
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <form className="w-full max-w-lg mx-auto">
                  {/* Your form fields */}
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="Campaign Name"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Campaign Name
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={utmCampaignName}
                        onChange={(e) => setUtmCampaignName(e.target.value)}
                        placeholder="Ranveer"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                      <label
                        htmlFor="lastName"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Campaign Source
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={utmCampaignSource}
                        onChange={(e) => setUtmCampaignSource(e.target.value)}
                        placeholder="Youtube"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="city"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Campaign Medium
                      </label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={utmCampaignMedium}
                        onChange={(e) => setUtmCampaignMedium(e.target.value)}
                        placeholder="play.google.com"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>

                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <label
                        htmlFor="zip"
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                      >
                        Campaign Term
                      </label>
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        value={utmCampaignTerm}
                        onChange={(e) => setUtmCampaignTerm(e.target.value)}
                        placeholder="app.apple.com"
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </div>
                  </div>
                </form>
                {/* Add Button */}
                <div className="text-center mt-4">
                  <button
                    onClick={() => {
                      // https://play.google.com/store/apps/details?id=level.game&referrer=utm_source%3Daarki%26utm_medium%3Dlevel-email%26utm_term%3Ddisco%26utm_content%3Ddance%26utm_campaign%3Dhehe%26anid%3Daarki%26aclid%3D{click_id}%26cp1%3D{app_id}
                      var url =
                        "https://play.google.com/store/apps/details?id=level.game&referrer=utm_source%3D" +
                        utmCampaignSource +
                        "%26utm_medium%3D" +
                        utmCampaignMedium +
                        "%26utm_term%3D" +
                        utmCampaignTerm +
                        "%26utm_content%3D" +
                        utmCampaignContent +
                        "%26utm_campaign%3D" +
                        utmCampaignName +
                        "%26anid%3Daarki%26aclid%3Dhehe%26cp1%3Dheheh";
                      setPlayUrl(url);
                      toast.success("UTM Created Successfully", {
                        icon: "🤌",
                      });
                    }}
                    className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                  >
                    Add Tracking
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mr-10">
            <button
              onClick={() => {
                syncData();
              }}
              className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                />
              </svg>
              Refresh Data
            </button>
          </div>


          <div className="flex flex-col h-screen">
            <div className="overflow-x-scroll sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <table className="min-w-full text-left text-sm font-light">
                  <thead className="sticky top-0 z-10 bg-white">
                    <tr>
                      <th scope="col" className="px-6 py-4">
                        Campaign Name
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Link Clicks
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Android Clicks
                      </th>

                      <th scope="col" className="px-6 py-4">
                        iOS Clicks
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Unknown Clicks
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Total Installs
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Link Clicks to Install rate
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Total Onboarding Completed
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Onboarding Not Completed
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total First Activity Completed
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total First Activity Not Completed
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Link clicks to subscription rate
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Installs to subscription rate
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Total Subscriptions Bought
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Total Subscriptions Cancelled
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Monthly Subscriptions Bought
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Six Monthly Subscriptions Bought
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Annual Subscriptions Bought
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Recursion 1st Month
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Recursion 2nd Month
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Total Recursion 3rd Month
                      </th>

                      <th scope="col" className="px-6 py-4">
                        View Analytics
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUtmData.map((item) => (
                      <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-neutral-500 ">
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.campaign_name}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.utm_builder_data?.click}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.utm_builder_data?.android_clicks}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.utm_builder_data?.ios_clicks}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.utm_builder_data?.unknown_clicks}
                        </td>

                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.total_installs}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {(!isNaN(item?.total_installs) && !isNaN(item?.utm_builder_data?.click) && item?.utm_builder_data?.click !== 0) ?
                            (((item?.total_installs / item?.utm_builder_data?.click) * 100).toFixed(2)) : 0} %
                        </td>

                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.total_onboarding_completed}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.total_onboarding_not_completed}
                        </td>

                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.first_activity_completed}
                        </td>

                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.first_activity_not_completed}
                        </td><td className="whitespace-nowrap px-6 py-4">
                          {(!isNaN(item?.utm_builder_data?.total_subscriptions) && !isNaN(item?.utm_builder_data?.click) && item?.utm_builder_data?.click !== 0) ?
                            (((item?.utm_builder_data?.total_subscriptions / item?.utm_builder_data?.click) * 100).toFixed(2)) : 0} %
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {(!isNaN(item?.utm_builder_data?.total_subscriptions) && !isNaN(item?.total_installs) && item?.total_installs !== 0) ?
                            (((item?.utm_builder_data?.total_subscriptions / item?.total_installs) * 100).toFixed(2)) : 0} %
                        </td>



                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.total_subscriptions}
                        </td>

                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.total_cancelled}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.monthly_subscriptions}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.sixMonth_subscriptions}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.annual_subscriptions}
                        </td>

                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.people_on_1_month}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.people_on_2_month}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          {item?.subscription_data?.people_on_3_month}
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <button
                            onClick={() => {
                              getGraphData(
                                item?.utm_builder_data?.short_uuid
                              );
                              setCurrentUuid(item?.short_uuid);
                              setShowGraph(true);
                            }}
                            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                          >
                            View Analytics
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      ) : (
        <>
          <div
            onClick={() => {
              setShowGraph(false);
            }}
          ></div>
          <GraphData value={graphData} />
        </>
      )}
    </>
  );
}

export default App;
